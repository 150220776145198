var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.approvalVisible,
            closable: true,
            size: "normal",
            title: "审批",
            "ok-text": "确认",
          },
          on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel },
        },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " 确定" +
                _vm._s(_vm.form.approvalStatus === "accept" ? "同意" : "拒绝") +
                " “" +
                _vm._s(_vm.form.flowName) +
                "” 吗？ "
            ),
          ]),
          _c(
            "div",
            { staticClass: "operation" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "opinion", label: "审批意见" } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: "请填写审批意见",
                          "auto-size": { minRows: 5, maxRows: 5 },
                          "max-length": 100,
                        },
                        model: {
                          value: _vm.form.opinion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "opinion", $$v)
                          },
                          expression: "form.opinion",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.signRequired
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "signFileId",
                            label: "签名",
                            "label-col": { span: _vm.signLabelWidth },
                          },
                        },
                        [
                          _vm.editSign
                            ? [
                                _c(
                                  "a-space",
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "link" },
                                        on: { click: _vm.saveAsImg },
                                      },
                                      [_vm._v("保存签名")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "link" },
                                        on: { click: _vm.clearSign },
                                      },
                                      [_vm._v("清空签名")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.editSign
                                  ? _c("SignCanvas", {
                                      ref: "SignCanvas",
                                      staticClass: "sign-canvas",
                                      attrs: { options: _vm.options },
                                      model: {
                                        value: _vm.sign,
                                        callback: function ($$v) {
                                          _vm.sign = $$v
                                        },
                                        expression: "sign",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass: "writeSign",
                                    on: { click: _vm.editSignFunc },
                                  },
                                  [
                                    !_vm.signFileId
                                      ? [_vm._v(" 手写签名 ")]
                                      : _c("img", {
                                          staticStyle: {
                                            width: "60px",
                                            height: "30px",
                                          },
                                          attrs: {
                                            src: _vm.signImgPath,
                                            alt: "",
                                            srcset: "",
                                          },
                                        }),
                                  ],
                                  2
                                ),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }